import { FEATURE_TOGGLES, isFeatureEnabled } from 'helpers/featureToggle';

export default {
  items: [
    {
      title: true,
      name: 'Backoffice',
      perm: 'read:activations',
    },
    {
      name: 'Activations',
      url: '/activations',
      icon: 'far fa-money-check-alt',
      perm: 'read:activations',
    },
    {
      name: 'Providers',
      url: '/providers',
      icon: 'far fa-address-card',
      children: [
        {
          name: 'Offers',
          url: '/offers',
          perm: 'read:offers',
        },
        {
          name: 'Distribution',
          url: '/offers/distribution-channels',
          perm: 'superadmin',
        },
        {
          name: 'Companies',
          url: '/companies',
          perm: 'read:companies',
        },
      ],
    },
    {
      name: 'Properties',
      url: '/properties',
      icon: 'far fa-map-marker-alt',
      perm: 'read:properties',
      children: [
        {
          name: 'Properties',
          url: '/properties',
          perm: 'read:properties',
        },
        {
          name: 'Place List',
          url: '/place-list',
          perm: 'read:properties',
        },
        {
          name: 'Zipcode Groups',
          url: '/zipcodegroups',
          perm: 'read:properties',
        },
      ],
    },
    {
      name: 'Dealers',
      url: '/dealers',
      icon: 'far fa-concierge-bell',
      perm: 'superadmin',
    },
    {
      name: 'Details Invalidator',
      url: '/invalidator',
      icon: 'fas fa-tools',
      perm: 'superadmin',
    },
    {
      name: 'People',
      url: '/people',
      icon: 'far fa-users',
      perm: 'superadmin',
      ...(isFeatureEnabled(FEATURE_TOGGLES.PEOPLE_MODULE) && {
        children: [
          {
            name: 'Old People',
            url: '/people',
            perm: 'superadmin',
          },
          {
            name: 'Persons',
            url: '/persons',
            perm: 'superadmin',
          },
          {
            name: 'Leases',
            url: '/leases',
            perm: 'superadmin',
          },
        ],
      }),
    },
    {
      title: true,
      name: 'Admin',
      perm: 'superadmin',
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'far fa-cogs',
      children: [
        {
          name: 'Custom Fields',
          url: '/custom-fields',
          perm: 'read:custom-fields',
        },
        {
          name: 'Service Types',
          url: '/service-types',
          perm: 'superadmin',
        },
        {
          name: 'Users',
          url: '/users/show',
          perm: 'read:users',
        },
      ],
    },
    {
      name: 'Services',
      url: '/services',
      icon: 'far fa-brackets-curly',
      children: [
        {
          name: 'Documents',
          url: '/documents',
          perm: 'read:documents',
        },
        {
          name: 'Importer',
          url: '/import',
          perm: 'superadmin',
          children: [
            {
              name: 'Imports',
              url: '/import/imports',
              perm: 'superadmin',
            },
            {
              name: 'Jobs',
              url: '/import/jobs',
              perm: 'superadmin',
            },
            {
              name: 'Sources',
              url: '/import/sources',
              perm: 'superadmin',
            },
            {
              name: 'Credentials',
              url: '/import/credentials',
              perm: 'superadmin',
            },
          ],
        },
        {
          name: 'Contact',
          url: '/contact',
          perm: 'superadmin',
          children: [
            {
              name: 'Emails',
              url: '/contact/emails',
              perm: 'superadmin',
            },
            {
              name: 'SMS',
              url: '/contact/sms',
              perm: 'superadmin',
            },
            {
              name: 'Templates',
              url: '/contact/email-templates',
              perm: 'superadmin',
            },
            {
              name: 'Send Settings',
              perm: 'superadmin',
              children: [
                {
                  name: 'SMS',
                  url: '/contact/send-settings/sms',
                  perm: 'superadmin',
                },
              ],
            },
          ],
        },
        {
          name: 'Affiliates',
          url: '/activations/affiliates',
          perm: 'read:affiliate-fees',
          children: [
            {
              name: 'Fees',
              url: '/activations/affiliates/fees',
              perm: 'read:affiliate-fees',
            },
            {
              name: 'Statements',
              url: '/activations/affiliates/statements',
              perm: 'read:affiliate-statements',
            },
          ],
        },
        {
          name: 'URLs',
          url: '/url-shorts',
          perm: 'read:urlshorts',
        },
        {
          name: 'Date Encrypt',
          url: '/crypt',
          perm: 'superadmin',
          children: [
            {
              name: 'Data',
              url: '/crypt/data',
              perm: 'superadmin',
            },
            {
              name: 'Keys',
              url: '/crypt/keys',
              perm: 'superadmin',
            },
          ],
        },
        {
          name: 'Triggers',
          url: '/triggers',
          perm: 'manage:global-triggers',
        },
      ],
    },
    {
      title: true,
      name: 'Account',
      perm: 'superadmin',
    },
    {
      name: 'My Profile',
      url: '/profile',
      icon: 'far fa-user-cog',
      perm: 'superadmin',
    },
  ],
};
